import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DealComputedResult } from '../interface/deal-analysis.interface';
@Injectable({
  providedIn: 'root',
})
export class DealAnalysisState {
  $dealAnalysisFormInput = new BehaviorSubject<any>({});
  dealAnalysisFormInput = this.$dealAnalysisFormInput.asObservable();
  $openDealAnalysisSheet = new BehaviorSubject<boolean>(false);
  openDealAnalysisSheet = this.$openDealAnalysisSheet.asObservable();
  $dealAnalysisResult = new BehaviorSubject<DealComputedResult>(null);
  dealAnalysisResult = this.$dealAnalysisResult.asObservable();
  dealListSub$ = new BehaviorSubject<any>(null);
  dealList$ = this.dealListSub$.asObservable();
  dealIdSub$ = new BehaviorSubject<any>(null);
  dealId$ = this.dealIdSub$.asObservable();
  private savedDealsSub$ = new BehaviorSubject<Array<any>>([]);
  savedDeals$ = this.savedDealsSub$.asObservable();
  private dealAnalysisPropertyPmxId$ = new BehaviorSubject<Number>(0);
  dealAnalysisPropertyId$ = this.dealAnalysisPropertyPmxId$.asObservable();
  private dealAnalysisDealId$ = new BehaviorSubject<Number>(0);
  dealAnalysisId$ = this.dealAnalysisDealId$.asObservable();
  dealListCountSub$ = new BehaviorSubject<Number>(0);
  dealListCount$ = this.dealListCountSub$.asObservable();
  $isSavedDealActive = new BehaviorSubject<boolean>(false);
  isSavedDealActive = this.$isSavedDealActive.asObservable();
  savedDealsCountSub$ = new BehaviorSubject<Number>(0);
  savedDealsCount$ = this.savedDealsCountSub$.asObservable();
  activePropertyDetailsSub = new BehaviorSubject<any>({});
  activePropertyDetails$ = this.activePropertyDetailsSub.asObservable();

  constructor() {}

  public get isSavedDealActiveValue() {
    return this.$isSavedDealActive.value;
  }

  public set isSavedDealActiveValue(status: boolean) {
    this.$isSavedDealActive.next(status);
  }

  public get dealListValue() {
    return this.dealListSub$.value;
  }

  public set dealListValue(dealList) {
    this.dealListSub$.next(dealList);
  }

  public get dealIdValue() {
    return this.dealIdSub$.value;
  }

  public set dealIdValue(dealList) {
    this.dealIdSub$.next(dealList);
  }

  public get dealListCountValue() {
    return this.dealListCountSub$.value;
  }

  public set dealListCountValue(count) {
    this.dealListCountSub$.next(count);
  }

  public get defaultDealAnalysisInputValue(): any {
    return this.$dealAnalysisFormInput.value;
  }

  public set defaultDealAnalysisInputValue(defaultInput: any) {
    this.$dealAnalysisFormInput.next(defaultInput);
  }

  public get openDealAnalysisSheetValue() {
    return this.$openDealAnalysisSheet.value;
  }

  public set openDealAnalysisSheetValue(status: boolean) {
    this.$openDealAnalysisSheet.next(status);
  }

  public get dealAnalysisResultValue(): DealComputedResult {
    return this.$dealAnalysisResult.value;
  }

  public set dealAnalysisResultValue(result: DealComputedResult) {
    this.$dealAnalysisResult.next(result);
  }

  public get dealAnalysisPropertyIdValue(): Number {
    return this.dealAnalysisPropertyPmxId$.value;
  }

  public set dealAnalysisPropertyIdValue(pmxPropertyId: Number) {
    this.dealAnalysisPropertyPmxId$.next(pmxPropertyId);
  }

  public get dealAnalysisIdValue(): Number {
    return this.dealAnalysisDealId$.value;
  }

  public set dealAnalysisIdValue(dealId: Number) {
    this.dealAnalysisDealId$.next(dealId);
  }

  public get savedDealsValue(): Array<any> {
    return this.savedDealsSub$.value;
  }

  public set savedDealsValue(dealList: Array<any>) {
    this.savedDealsSub$.next(dealList);
  }

  public get savedDealsCountValue(): Number {
    return this.savedDealsCountSub$.value;
  }

  public set savedDealsCountValue(count: Number) {
    this.savedDealsCountSub$.next(count);
  }
  public get activePropertyDetailsValue(): object {
    return this.activePropertyDetailsSub.value;
  }

  public set activePropertyDetailsValue(property: object) {
    this.activePropertyDetailsSub.next(property);
  }
}
