import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() chartData: Array<any>;
  @Input() chartSettings;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  chartOptions: Highcharts.Options = {};

  constructor() {}

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
  }

  ngOnChanges(): void {
    if (!this.chartData || !this.chartData.length || !this.chartSettings) {
      return;
    }
    this.createChart();
  }

  private createChart(): void {
    this.chartOptions = {
      title: this.chartSettings.title,
      subtitle: this.chartSettings.subtitle,
      yAxis: this.chartSettings.yAxis,
      xAxis: this.chartSettings.xAxis,
      series: this.chartData,
      credits: { enabled: false },
      plotOptions: {
        series: {
          marker: { enabled: false },
          // showCheckbox: true,
          selected: true,
          events: {
            checkboxClick: function () {
              this.setVisible(!this.visible);
            },
            legendItemClick: function () {
              const seriesIndex = this.index;
              this.chart.series[seriesIndex].select();
            },
          },
        },
      },
      legend: {
        enabled: true, // Ensure legend is enabled
        borderColor: 'transparent',
        align: 'right',
        x: 14,
        layout: 'vertical',
        verticalAlign: 'middle',
        borderWidth: 1,
        useHTML: true,
        labelFormatter: function () {
          let checked = '';
          if (this.visible) {
            return `
            <div style="display:flex;">
             <svg style="margin-right: 5px;margin-bottom:10px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${this['color']}" d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
              <span>${this.name}</span>
             </div>
             
            `;
          }
          return `
          <div style="display:flex;">
            <svg style="margin-right: 5px;margin-bottom:10px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${this['color']}" d="M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z"/></svg>
            <span>${this.name}</span>
            </div>
          `;
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
              yAxis: {
                labels: {
                  align: 'left',
                  x: 0,
                  y: 0,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    };

    this.updateFlag = true; // Trigger update of chart component
  }
}

// import { Component, Input, OnChanges, OnInit } from '@angular/core';
// import * as Highcharts from 'highcharts';

// @Component({
//   selector: 'app-line-chart',
//   templateUrl: './line-chart.component.html',
//   styleUrls: ['./line-chart.component.scss'],
// })
// export class LineChartComponent implements OnInit, OnChanges {
//   @Input() chartData: Array<any>;
//   @Input() chartSettings;
//   Highcharts: typeof Highcharts = Highcharts;
//   updateFlag = false;
//   chartOptions: Highcharts.Options = {};

//   constructor() {}

//   ngOnInit() {
//     Highcharts.setOptions({
//       lang: {
//         thousandsSep: ',',
//       },
//     });
//   }

//   ngOnChanges(): void {
//     if (!this.chartData || !this.chartData.length || !this.chartSettings) {
//       return;
//     }
//     this.createChart();
//   }

//   private createChart(): void {
//     this.chartOptions = {
//       title: this.chartSettings.title,
//       subtitle: this.chartSettings.subtitle,
//       yAxis: this.chartSettings.yAxis,
//       xAxis: this.chartSettings.xAxis,
//       series: this.chartData,
//       credits: { enabled: false },
//       plotOptions: {
//         series: {
//           marker: { enabled: false },
//           selected: true,
//           events: {
//             checkboxClick: function () {
//               this.setVisible(!this.visible);
//             },
//             legendItemClick: function () {
//               const seriesIndex = this.index;
//               this.chart.series[seriesIndex].select();
//             },
//           },
//         },
//       },
//       legend: {
//         enabled: true,
//         borderColor: 'transparent',
//         align: 'right',
//         x: 14,
//         layout: 'vertical',
//         verticalAlign: 'middle',
//         borderWidth: 1,
//         useHTML: true,
//         labelFormatter: function () {
//           return this.visible
//             ? `<div style="display:flex;">
//                  <svg style="margin-right: 5px;margin-bottom:10px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="" d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
//                  <span>${this.name}</span>
//                </div>`
//             : `<div style="display:flex;">
//                  <svg style="margin-right: 5px;margin-bottom:10px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="" d="M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z"/></svg>
//                  <span>${this.name}</span>
//                </div>`;
//         },
//       },
//       responsive: {
//         rules: [
//           {
//             condition: {
//               maxWidth: 500,
//             },
//             chartOptions: {
//               legend: {
//                 align: 'center',
//                 verticalAlign: 'bottom',
//                 layout: 'horizontal',
//               },
//               yAxis: {
//                 labels: {
//                   align: 'left',
//                   x: 0,
//                   y: -5,
//                 },
//                 title: {
//                   text: null,
//                 },
//               },
//               subtitle: {
//                 text: null,
//               },
//               credits: {
//                 enabled: false,
//               },
//             },
//           },
//         ],
//       },
//     };

//     this.updateFlag = true; // Trigger update of chart component
//   }
// }
