import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import constants from 'src/constant.json';
import { environment } from 'src/environments/environment';

export interface ValidateUserRestLink {
  Message: string;
  StatusCode: string;
  linkStatus: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  constructor(private http: HttpClient) {}

  getPasswordResetLink(data: string, companyId?): Observable<any> {
    const resetObject = {
      userName: data,
      companyId: companyId,
    };
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.getPasswordResetLink, resetObject).subscribe(
        (response) => {
          if (response) {
            observer.next(response);
          }
        },
        (error) => {
          observer.error(error.error);
        }
      );
    });
  }

  resetPassword(data: object): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.resetPassword, data).subscribe(
        (response) => {
          if (response) {
            observer.next(response);
          }
        },
        (error) => {
          // console.log('Error occurred: ', error);
          observer.error(error.error);
        }
      );
    });
  }
  validateUserResetToken(token): Observable<any> {
    return new Observable((observer) => {
      const params = {
        userResetToken: token,
      };
      this.http.post(environment.prospektrBackendBaseURL + constants.API.validatePasswordResetLink, params).subscribe(
        (response: ValidateUserRestLink) => {
          if (response) {
            observer.next({ staus: response.linkStatus, message: response.Message });
          }
        },
        (error) => {
          observer.error({ staus: false, message: error.error.Message });
        }
      );
    });
  }
}
