import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MyHomeState {
  private myHomeListSub$ = new BehaviorSubject<any>([]);
  myHomeList$ = this.myHomeListSub$.asObservable();

  private activeHomeSub$ = new BehaviorSubject<any>({});
  activeHome$ = this.activeHomeSub$.asObservable();

  private mortgageListSub$ = new BehaviorSubject<any>([]);
  mortgageList$ = this.mortgageListSub$.asObservable();

  private activeHomeValuesSub$ = new BehaviorSubject<any>([]);
  activeHomeValues$ = this.activeHomeValuesSub$.asObservable();

  private activeHomeValuesLoaderSub$ = new BehaviorSubject<boolean>(true);
  activeHomeValuesLoader$ = this.activeHomeValuesLoaderSub$.asObservable();

  private selectedMortgageIndexSub$ = new BehaviorSubject<number>(0);
  selectedMortgageIndex$ = this.selectedMortgageIndexSub$.asObservable();

  private openSpendForecastSheetSub$ = new BehaviorSubject<boolean>(false);
  openSpendForecastSheet$ = this.openSpendForecastSheetSub$.asObservable();

  private activePropertyDetailsSub$ = new BehaviorSubject<any>({});
  activePropertyDetails$ = this.activePropertyDetailsSub$.asObservable();
  // private activeRefinanceSub$ = new BehaviorSubject<any>(null);

  constructor() {}

  public get selectedMortgageIndexValue() {
    return this.selectedMortgageIndexSub$.value;
  }

  public set selectedMortgageIndexValue(index) {
    this.selectedMortgageIndexSub$.next(index);
  }

  public get myHomeListValue() {
    return this.myHomeListSub$.value;
  }

  public set myHomeListValue(list) {
    this.myHomeListSub$.next(list);
  }

  public get mortgageListValue() {
    return this.mortgageListSub$.value;
  }

  public set mortgageListValue(list) {
    this.mortgageListSub$.next(list);
  }

  public get activeHomeValue() {
    return this.activeHomeSub$.value;
  }

  public set activeHomeValue(list) {
    this.activeHomeSub$.next(list);
  }

  public get activeHomePropertyValue() {
    return this.activeHomeValuesSub$.value;
  }

  public set activeHomePropertyValue(list) {
    this.activeHomeValuesSub$.next(list);
  }

  public get activeHomePropertyLoaderValue() {
    return this.activeHomeValuesLoaderSub$.value;
  }

  public set activeHomePropertyLoaderValue(status) {
    this.activeHomeValuesLoaderSub$.next(status);
  }

  public get openSpendForecastSheetValue(): boolean {
    return this.openSpendForecastSheetSub$.value;
  }

  public set openSpendForecastSheetValue(status: boolean) {
    this.openSpendForecastSheetSub$.next(status);
  }

  public get activePropertyDetailsValue(): object {
    return this.activePropertyDetailsSub$.value;
  }

  public set activePropertyDetailsValue(property: object) {
    this.activePropertyDetailsSub$.next(property);
  }

  private refinanceScenarioListSub$ = new BehaviorSubject<any>({});
  refinanceScenarioList$ = this.refinanceScenarioListSub$.asObservable();

  public get refinanceScenarioListValue(): object {
    return this.refinanceScenarioListSub$.value;
  }

  public set refinanceScenarioListValue(property: object) {
    this.refinanceScenarioListSub$.next(property);
  }

  private homeListUpdateSub$ = new BehaviorSubject<any>([]);
  homeListUpdate$ = this.homeListUpdateSub$.asObservable();

  public get homeListUpdateValue(): object {
    return this.homeListUpdateSub$.value;
  }

  public set homeListUpdateValue(property: object) {
    this.homeListUpdateSub$.next(property);
  }
}
