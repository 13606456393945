import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProspektrApi {
  private logOut = 'logOut';
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  // prospektrBackendBaseURL = 'http://localhost:3000/';
  private agentIdList = 'agentIdList';
  private agentMlsApi = 'agentMls';
  private companyMlsApi = 'getMlsData';
  private brandingPartnerApi = 'brandingPartner';
  private businessAreaApi = 'business-areas';
  private campaignUsersApi = 'campaignUsers';
  private clientApi = 'client';
  private brandApi = 'brand';

  private associationBoard = 'association-boards';
  private connection = 'connection';
  private contactAgentApi = 'contactAgent';
  private contactApi = 'contact-groups';
  private contactLenderApi = 'contactLender';
  private contacts = 'contacts';
  private contactUploads = 'contacts/upload';
  private dealAnalysisByAddressApi = 'dealAnalysisByAddress';
  private deleteDealApi = 'dealAnalysis';
  private deleteMortgageApi = 'mortgage';
  private deletePropertyAlertApi = 'deletePropertyAlert';
  private getAllPropertyAlertApi = 'propertyAlert';
  private getCampaignListApi = 'campaigns';
  private getClientInfoByIdApi = 'getClientInfoById';
  private getConnectionInfoByUserTokenApi = 'connectionByUserToken';
  private getDealListApi = 'dealAnalysis';
  private getLoggedActivitiesApi = 'activityLog';
  private getPropertyValuesApi = 'getPropertyValues';
  private getSavedDealsApi = 'savedDealAddress';
  private getUnreadPropertyAlertApi = 'unReadPropertyAlert';
  private interestedAreaApi = 'interestedArea';
  private inviteUserApi = 'inviteUser';
  private inviteLoanOfficerApi = 'inviteLoanOfficer';
  private inviteAppraiserApi = 'inviteAppraiser';
  private logActivityApi = 'logActivity';
  private userActivityApi = 'userActivity';
  private myHomeApi = 'myHome';
  private partnersApi = 'partners';
  private planUpgradeRequestApi = 'planUpgrade';
  private reportApi = 'report';
  private saveDealApi = 'dealAnalysis';
  private setAsDefaultPartnerApi = 'defaultConnection';
  private signupContinueApi = 'signupContinue';
  private signupInvitedUserApi = 'signupInvitedUser';
  private updateDealApi = 'dealAnalysis';
  private updateOnboardRequestApi = 'updateOnboardRequest';
  private uploadToS3Api = 'uploadToS3';
  private userAuthenticateApi = 'userAuthenticate';
  private socialMediaSignInApi = 'socialMediaSignIn';
  private preApprovalApi = 'preApproval';
  private questionnaireResponseApi = 'questionnaireResponse';
  private connectedAgents = 'getConnectedAgents';

  constructor(private http: HttpClient) { }

  loginUsers(param): Observable<any> {
    const encodedEmail = encodeURIComponent(param.email);
    return this.http.get(`${this.prospektrBackendBaseURL}users/company`, {
      params: { email: encodedEmail },
    });
  }

  userAuthenticate(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.userAuthenticateApi}`, {
      userName: params.email,
      password: params.password,
      companyId: params.companyId,
    });
  }
  socialMediaSignIn(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.socialMediaSignInApi}`, params);
  }
  uploadToS3(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.uploadToS3Api}`, params);
  }

  createUser(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}signupUser`, params);
  }

  preapprovalAction(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.preApprovalApi}`, params);
  }

  signUpContinue(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.signupContinueApi}`, params);
  }

  getCampaignList(params?): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getCampaignListApi}`, {
      params: params,
    });
  }

  updateCampaign(campaignId, campaign): Observable<any> {
    return this.http.patch(`${this.prospektrBackendBaseURL}campaigns/${campaignId}`, campaign);
  }

  deleteCampaign(campaignId: string): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}campaigns/${campaignId}`);
  }

  createCampaign(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}campaigns`, params);
  }

  launchCampaign(campaignId: string, frequency: string = null): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}campaigns/${campaignId}/launch`, {
      params: { frequency },
    });
  }

  getCampaignCandidates(campaignId: string): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}campaigns/${campaignId}/candidates`);
  }

  createCampaignUsers(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.campaignUsersApi}`, params);
  }

  getClientInfoById(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.getClientInfoByIdApi}`, params);
  }

  getClientInfoByUserToken(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getConnectionInfoByUserTokenApi}`);
  }

  updateOnboardRequest(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.updateOnboardRequestApi}`, params);
  }

  signupInvitedUser(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.signupInvitedUserApi}`, params);
  }

  planUpgradeRequest(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.planUpgradeRequestApi}`, params);
  }

  campaignOnboard(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}campaign-onboard/${params}`);
  }

  listAddressCsvRecord(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.contactApi}/${params}/${this.contacts}`);
  }

  contactsList(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}contact-group-list`, params);
  }

  selectedContacts(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}contact-selected-list`, params);
  }

  campaignInfo(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}campaign-statistics`, params);
  }

  addContactList(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}contact-list`, params);
  }

  getDealList(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getDealListApi}`, {
      params: params,
    });
  }

  getDealById(id) {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getDealListApi}/${id}`);
  }

  updateDeal(params): Observable<any> {
    return this.http.patch(`${this.prospektrBackendBaseURL}${this.updateDealApi}`, params);
  }

  deleteDeal(params): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.deleteDealApi}`, {
      params: params,
    });
  }

  saveDeal(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.saveDealApi}`, params);
  }

  logActivity(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.logActivityApi}`, params);
  }

  userActivity(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.userActivityApi}`, params);
  }

  fetchInvestors(params?) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors`, params);
  }

  fetchInvestorsUnderMarketingEffectiveness(params?) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/marketingEffectiveness`, params);
  }

  fetchEmailDetails(params?) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/marketingHistory`, params);
  }

  fetchClientDetailsUnderMarketing(params?) {
    const requestBody = params;
    return this.http.post(`${this.prospektrBackendBaseURL}investors/marketingStatistics`, requestBody);
  }

  fetchAgentsAndLoanOfficers(params = {}) {
    return this.http.post(`${this.prospektrBackendBaseURL}client-users`, params);
  }

  getSavedDeals(params) {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getSavedDealsApi}`, {
      params: params,
    });
  }

  getLoggedActivities(params) {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getLoggedActivitiesApi}`, {
      params: params,
    });
  }

  getSentMails(investorId) {
    return this.http.get(`${this.prospektrBackendBaseURL}investors/${investorId}/mail`);
  }

  addSavedSearch(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/${params.investorId}/saved-searches`, params);
  }

  getUnReadPropertyAlert(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getUnreadPropertyAlertApi}`, {
      params: params,
    });
  }

  getPropertyAlert(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getAllPropertyAlertApi}`, {
      params: params,
    });
  }

  deleteAlert(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.deletePropertyAlertApi}`, params);
  }

  dealAnalysisByAddress(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.dealAnalysisByAddressApi}`, {
      params: params,
    });
  }

  getAgentMls(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.agentMlsApi}`);
  }

  getCompanyMLSList(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.companyMlsApi}`);
  }

  getMLSBoards(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}mls-boards`, {
      params: params,
    });
  }

  saveAgentMls(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.agentMlsApi}`, params);
  }

  deleteAgentMls(params): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.agentMlsApi}`, {
      params: params,
    });
  }

  createBusinessAreas(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.businessAreaApi}`, params);
  }

  getBusinessAreas(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.businessAreaApi}`);
  }

  deleteBusinessArea(params): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.businessAreaApi}/${params}`);
  }

  fetchActivePartners(params?) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.partnersApi}`, params);
  }

  setAsDefaultPartner(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.setAsDefaultPartnerApi}`, params);
  }

  inviteAppraiser(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.inviteAppraiserApi}`, params);
  }

  inviteLoanOfficer(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.inviteLoanOfficerApi}`, params);
  }

  inviteUser(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.inviteUserApi}`, params);
  }

  inviteInvestor(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}campaigns/investor-invitation`, params);
  }

  downloadAttchment(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/${params.id}/download`, params);
  }

  getBrandingPartnersInfo(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.brandingPartnerApi}`, {
      params: params,
    });
  }

  getInterestedArea(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.interestedAreaApi}`);
  }

  createInterestedArea(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.interestedAreaApi}`, params);
  }

  deleteInterestedArea(params): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.interestedAreaApi}`, {
      params: params,
    });
  }

  uploadContacts(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.contactUploads}`, params);
  }

  getContactGroups(contactId?) {
    let getContactUrl = this.contactApi;
    if (contactId) {
      getContactUrl = this.contactApi + '/' + contactId;
    }
    return this.http.get(`${this.prospektrBackendBaseURL}${getContactUrl}`);
  }

  deleteContactFileList(idList) {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.contactApi}/${idList}`);
  }

  getClient(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.clientApi}`);
  }

  getBrandInfo(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.brandApi}`);
  }

  getUserPortfolio(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}user/portfolio`);
  }

  updatePortfolio(params) {
    return this.http.put(`${this.prospektrBackendBaseURL}user/portfolio`, params);
  }

  getAssociation(params) {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.associationBoard}`, {
      params: params,
    });
  }

  uploadImage(file): Observable<any> {
    const formData = new FormData();
    formData.append('image', file, file.name);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    };
    return this.http.post(`${this.prospektrBackendBaseURL}misc/image-upload`, formData, httpOptions);
  }

  sentMail(file, id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    };
    return this.http.post(`${this.prospektrBackendBaseURL}investors/${id}/mail`, file, httpOptions);
  }

  patchClient(clientId, params): Observable<any> {
    return this.http.patch(`${this.prospektrBackendBaseURL}${this.clientApi}/${clientId}`, params);
  }

  patchBrandInfo(userId, params): Observable<any> {
    return this.http.patch(`${this.prospektrBackendBaseURL}${this.brandApi}/${userId}`, params);
  }

  updateCampaignUsers(params): Observable<any> {
    return this.http.put(`${this.prospektrBackendBaseURL}${this.campaignUsersApi}`, params);
  }

  getHomeList() {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.myHomeApi}`);
  }

  saveHome(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.myHomeApi}`, params);
  }

  getPropertyValues(params) {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getPropertyValuesApi}`, {
      params: params,
    });
  }

  updateMyHome(params) {
    return this.http.patch(`${this.prospektrBackendBaseURL}${this.myHomeApi}`, params);
  }

  deleteMyHome(id) {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.myHomeApi}/${id}`);
  }

  contactAgentInfo() {
    return this.http.get(`${this.prospektrBackendBaseURL}agentDetails`);
  }

  contactAgent(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.contactAgentApi}`, params);
  }

  notifyContactAgent(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}notifyAgent`, params);
  }

  contactLender(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.contactLenderApi}`, params);
  }

  deleteMortgage(id) {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.deleteMortgageApi}/${id}`);
  }

  getReport(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.reportApi}`, {
      params: { ...params, limit: params.limit || 10, offset: params.offset || 0 },
    });
  }

  getAgentIdList(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.agentIdList}`);
  }

  updateLeadStatus(investorId, params) {
    return this.http.patch(`${this.prospektrBackendBaseURL}investors/${investorId}/lead`, params);
  }

  updateInvestorConnection(investorId, params) {
    return this.http.patch(`${this.prospektrBackendBaseURL}investors/${investorId}/connection`, params);
  }

  getUsersNote(investorId) {
    return this.http.get(`${this.prospektrBackendBaseURL}investors/${investorId}/notes`);
  }

  addUserNote(investorId, params) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/${investorId}/notes`, params);
  }

  updateUserNote(noteId, params) {
    return this.http.patch(`${this.prospektrBackendBaseURL}investors/notes/${noteId}`, params);
  }

  deleteUserNote(noteId) {
    return this.http.delete(`${this.prospektrBackendBaseURL}investors/notes/${noteId}`);
  }

  resendActivationMail(investorId) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/${investorId}/signUpReminder`, null);
  }

  getConnectionDetail(params) {
    return this.http.get(`${this.prospektrBackendBaseURL}investors/${params}/${this.connection}`);
  }

  leadLogCountDetail() {
    return this.http.get(`${this.prospektrBackendBaseURL}investors/statistics`);
  }

  exportMyClientsToCSV(params?) {
    return this.http.post(`${this.prospektrBackendBaseURL}investors/export`, params);
  }

  exportUserListToCSV(params: any): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}exportUsers`, {
      params: params,
    });
  }
  updateUnsubscribeRecommendation(params) {
    return this.http.post(`${this.prospektrBackendBaseURL}subscription/unsubscribe`, params);
  }

  getMyListings(params: any): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}investors/myListings`, {
      params: params,
    });
  }

  getPlans(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}plans`, { params: params });
  }

  getConnectedLoanOfficers(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}connectedUsers`);
  }

  getConnectedAgents(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.connectedAgents}`);
  }

  applyCode(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}feature/apply-coupon`, { params: params });
  }

  subscribePlan(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}feature/subscribe`, params);
  }

  logOutUser(): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.logOut}`, null).pipe(catchError(() => EMPTY));
  }
  getQuestionnaireResponse(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.questionnaireResponseApi}`);
  }
}
