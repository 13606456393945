import { CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';

// Angular Material Module imports
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';

import { PktrAppMenuModule, PktrAppMenuService } from '@prospektr/pktr-app-menu';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { Observable } from 'rxjs';
import { CoreModule } from './_core/core.module';
import { TrialAccountValidatorGuard } from './_shared/guard/trial-account-validator.guard';
import { DurationPipe } from './_shared/pipe/duration.pipe';
import { NumberFormatterPipe } from './_shared/pipe/number.pipe';
import { AppInitializer } from './_shared/resolver/app.initializer';
import { DealAnalysisService } from './_shared/service/deal-analysis.service';
import { GTMService } from './_shared/service/gtm.service';
import { WindowService } from './_shared/service/window.service';
import { SharedModule } from './_shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { ContainerPageComponent } from './modal-container/component/container-page/container-page.component';
import { StatusInfoComponent } from './modal-container/component/status-info/status-info.component';
import { OnboardRequestComponent } from './onboard-request/component/onboard-request/onboard-request.component';
import { ProfileService } from './profile/profile.service';
import { QuestionnaireResponseComponent } from './questionnaire-response/questionnaire-response.component';
import { AlertsModule } from './shared/modules/alerts/alerts.module';
import { CustomUrlSerializer } from './shared/service/customUrlSerializer/custom-url-serializer.service';
import { HttpInterceptorService } from './shared/service/httpInterceptor/http-interceptor.service';
import { LocalStorageService } from './shared/service/local-storage.service';
import { SubscriptionPlanModule } from './subscription-plan/subscription-plan.module';

export function appInitializer(initializerService: AppInitializer): () => Observable<void> {
  return () => initializerService.initializeApp();
}

export const options: Partial<IConfig> = {
  thousandSeparator: ',',
};

@NgModule({
  declarations: [
    AppComponent,
    ContainerPageComponent,
    DurationPipe,
    NumberFormatterPipe,
    OnboardRequestComponent,
    QuestionnaireResponseComponent,
    StatusInfoComponent,
  ],
  imports: [
    AlertsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    ReactiveFormsModule,
    SharedModule,
    SubscriptionPlanModule,
    PktrAppMenuModule,
    NgxMaskModule.forRoot(options),
  ],
  providers: [
    AppService,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    DurationPipe,
    GTMService,
    LocalStorageService,
    NumberFormatterPipe,
    PktrAppMenuService,
    TitleCasePipe,
    TrialAccountValidatorGuard,
    UpperCasePipe,
    //  ShortNumberPipe,
    WindowService,
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AppInitializer],
      multi: true,
    },
  ],
  exports: [MatFormFieldModule, MatInputModule, MatCheckboxModule, DurationPipe, NumberFormatterPipe],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private appService: AppService,
    private dealAnalysisService: DealAnalysisService,
    private profileService: ProfileService
  ) {}
}
