import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ReportsApi {
  prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  // prospektrBackendBaseURL = "http://localhost:3000/";

  getListingDetailsApi = 'getListingDetails';
  getPropertyDetailsApi = 'getPropertyDetails';
  searchPropertyDetailsApi = 'searchProperty';

  reportsApi = 'valueReport';
  sharedValueReportApi = 'sharedValueReport';
  refreshReportsApi = 'refreshReport';
  getReportsApi = 'getAllValueReports';
  getPlanStats = 'plan-stats';
  downloadReportApi = 'downloadValueReport';
  downloadReportHtmlApi = 'downloadValueReportHtml';
  shareReportApi = 'shareReportUrl';

  constructor(private http: HttpClient) { }

  getPlanStatus(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.getPlanStats}`);
  }

  deleteReport(params): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.reportsApi}`, {
      params: params,
    });
  }

  getAllReports(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getReportsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getReportById(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .get(`${this.prospektrBackendBaseURL}${this.reportsApi}/${params.id}`)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getSharedReport(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .get(`${this.prospektrBackendBaseURL}${this.sharedValueReportApi}/${params.id}/${params.type}`)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  createReport(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.reportsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  updateReport(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .patch(`${this.prospektrBackendBaseURL}${this.reportsApi}/${params.id}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  refreshReport(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .patch(`${this.prospektrBackendBaseURL}${this.refreshReportsApi}/${params.id}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  downloadReport(params): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.downloadReportApi}`, params, httpOptions)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  downloadReportAsHtml(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.downloadReportHtmlApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getListingDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getListingDetailsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getPropertyDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getPropertyDetailsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  searchPropertyDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.searchPropertyDetailsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  shareReport(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.shareReportApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }
}
